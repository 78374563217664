define("discourse/plugins/discourse-chat-integration/admin/controllers/modals/admin-plugins-chat-integration-test", ["exports", "@ember/controller", "I18n", "discourse/mixins/modal-functionality", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "@ember/runloop"], function (_exports, _controller, _I18n, _modalFunctionality, _ajax, _ajaxError, _decorators, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, (_dec = (0, _decorators.on)("init"), _dec2 = (0, _decorators.default)("model.topic_id"), (_obj = {
    setupKeydown() {
      (0, _runloop.schedule)("afterRender", () => {
        $("#chat_integration_test_modal").keydown(e => {
          if (e.keyCode === 13) {
            this.send("send");
          }
        });
      });
    },
    sendDisabled(topicId) {
      return !topicId;
    },
    actions: {
      send() {
        if (this.get("sendDisabled")) {
          return;
        }
        this.set("loading", true);
        (0, _ajax.ajax)("/admin/plugins/chat-integration/test", {
          data: {
            channel_id: this.get("model.channel.id"),
            topic_id: this.get("model.topic_id")
          },
          type: "POST"
        }).then(() => {
          this.set("loading", false);
          this.flash(_I18n.default.t("chat_integration.test_modal.success"), "success");
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "setupKeydown", [_dec], Object.getOwnPropertyDescriptor(_obj, "setupKeydown"), _obj), _applyDecoratedDescriptor(_obj, "sendDisabled", [_dec2], Object.getOwnPropertyDescriptor(_obj, "sendDisabled"), _obj)), _obj)));
});