define("discourse/plugins/discourse-chat-integration/admin/controllers/modals/admin-plugins-chat-integration-edit-channel", ["exports", "@ember/controller", "I18n", "discourse/mixins/modal-functionality", "discourse/lib/ajax-error", "@ember/object", "discourse-common/utils/decorators", "@ember/runloop"], function (_exports, _controller, _I18n, _modalFunctionality, _ajaxError, _object, _decorators, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, (_dec = (0, _decorators.on)("init"), _dec2 = (0, _decorators.observes)("model"), _dec3 = (0, _decorators.default)("paramValidation"), (_obj = {
    setupKeydown() {
      (0, _runloop.schedule)("afterRender", () => {
        $("#chat-integration-edit-channel-modal").keydown(e => {
          if (e.keyCode === 13) {
            this.send("save");
          }
        });
      });
    },
    setupValidations() {
      if (this.get("model.provider")) {
        const theKeys = this.get("model.provider.channel_parameters").map(param => param["key"]);
        (0, _object.defineProperty)(this, "paramValidation", (0, _object.computed)(`model.channel.data.{${theKeys.join(",")}}`, this._paramValidation));
        this.notifyPropertyChange("paramValidation");
      }
    },
    validate(parameter) {
      const regString = parameter.regex;
      const regex = new RegExp(regString);
      let val = this.get(`model.channel.data.${parameter.key}`);
      if (val === undefined) {
        val = "";
      }
      if (val === "") {
        // Fail silently if field blank
        return _object.default.create({
          failed: true
        });
      } else if (!regString) {
        // Pass silently if no regex available for provider
        return _object.default.create({
          ok: true
        });
      } else if (regex.test(val)) {
        // Test against regex
        return _object.default.create({
          ok: true,
          reason: _I18n.default.t("chat_integration.edit_channel_modal.channel_validation.ok")
        });
      } else {
        // Failed regex
        return _object.default.create({
          failed: true,
          reason: _I18n.default.t("chat_integration.edit_channel_modal.channel_validation.fail")
        });
      }
    },
    _paramValidation() {
      const response = {};
      const parameters = this.get("model.provider.channel_parameters");
      parameters.forEach(parameter => {
        response[parameter.key] = this.validate(parameter);
      });
      return response;
    },
    saveDisabled(paramValidation) {
      if (!paramValidation) {
        return true;
      }
      let invalid = false;
      Object.keys(paramValidation).forEach(key => {
        if (!paramValidation[key]) {
          invalid = true;
        }
        if (!paramValidation[key]["ok"]) {
          invalid = true;
        }
      });
      return invalid;
    },
    actions: {
      cancel() {
        this.send("closeModal");
      },
      save() {
        if (this.get("saveDisabled")) {
          return;
        }
        this.get("model.channel").save().then(() => {
          this.send("closeModal");
        }).catch(_ajaxError.popupAjaxError);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "setupKeydown", [_dec], Object.getOwnPropertyDescriptor(_obj, "setupKeydown"), _obj), _applyDecoratedDescriptor(_obj, "setupValidations", [_dec2], Object.getOwnPropertyDescriptor(_obj, "setupValidations"), _obj), _applyDecoratedDescriptor(_obj, "saveDisabled", [_dec3], Object.getOwnPropertyDescriptor(_obj, "saveDisabled"), _obj)), _obj)));
});