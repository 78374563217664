define("discourse/plugins/discourse-chat-integration/admin/controllers/modals/admin-plugins-chat-integration-edit-rule", ["exports", "@ember/controller", "discourse/mixins/modal-functionality", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "@ember/runloop"], function (_exports, _controller, _modalFunctionality, _ajaxError, _decorators, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, (_dec = (0, _decorators.on)("init"), _dec2 = (0, _decorators.default)("model.rule.type"), (_obj = {
    saveDisabled: false,
    setupKeydown() {
      (0, _runloop.schedule)("afterRender", () => {
        $("#chat-integration-edit-channel-modal").keydown(e => {
          if (e.keyCode === 13) {
            this.send("save");
          }
        });
      });
    },
    showCategory(type) {
      return type === "normal";
    },
    actions: {
      save(rule) {
        if (this.get("saveDisabled")) {
          return;
        }
        rule.save().then(() => this.send("closeModal")).catch(_ajaxError.popupAjaxError);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "setupKeydown", [_dec], Object.getOwnPropertyDescriptor(_obj, "setupKeydown"), _obj), _applyDecoratedDescriptor(_obj, "showCategory", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showCategory"), _obj)), _obj)));
});