define("discourse/plugins/discourse-chat-integration/admin/components/channel-details", ["exports", "@ember/component", "discourse/lib/ajax-error", "I18n", "bootbox"], function (_exports, _component, _ajaxError, _I18n, _bootbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ["channel-details"],
    actions: {
      deleteChannel(channel) {
        _bootbox.default.confirm(_I18n.default.t("chat_integration.channel_delete_confirm"), _I18n.default.t("no_value"), _I18n.default.t("yes_value"), result => {
          if (result) {
            channel.destroyRecord().then(() => this.refresh()).catch(_ajaxError.popupAjaxError);
          }
        });
      },
      editRule(rule) {
        this.editRuleWithChannel(rule, this.get("channel"));
      }
    }
  });
});