define("discourse/plugins/discourse-chat-integration/admin/routes/admin-plugins-chat-integration-index", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    afterModel(model) {
      if (model.totalRows > 0) {
        this.transitionTo("adminPlugins.chat-integration.provider", model.get("firstObject").name);
      }
    }
  });
});