define("discourse/plugins/discourse-chat-integration/admin/models/rule", ["exports", "I18n", "discourse/models/rest", "discourse/models/category", "discourse-common/utils/decorators"], function (_exports, _I18n, _rest, _category, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _rest.default.extend((_dec = (0, _decorators.default)("channel.provider"), _dec2 = (0, _decorators.observes)("type"), _dec3 = (0, _decorators.default)("category_id"), _dec4 = (0, _decorators.default)("filter"), (_obj = {
    available_filters(provider) {
      const available = [];
      if (provider === "slack") {
        available.push({
          id: "thread",
          name: _I18n.default.t("chat_integration.filter.thread"),
          icon: "chevron-right"
        });
      }
      available.push({
        id: "watch",
        name: _I18n.default.t("chat_integration.filter.watch"),
        icon: "exclamation-circle"
      }, {
        id: "flagged",
        name: _I18n.default.t("chat_integration.filter.flagged"),
        icon: "exclamation-circle"
      }, {
        id: "follow",
        name: _I18n.default.t("chat_integration.filter.follow"),
        icon: "circle"
      }, {
        id: "mute",
        name: _I18n.default.t("chat_integration.filter.mute"),
        icon: "times-circle"
      });
      return available;
    },
    available_types: [{
      id: "normal",
      name: _I18n.default.t("chat_integration.type.normal")
    }, {
      id: "group_message",
      name: _I18n.default.t("chat_integration.type.group_message")
    }, {
      id: "group_mention",
      name: _I18n.default.t("chat_integration.type.group_mention")
    }],
    category_id: null,
    tags: null,
    channel_id: null,
    filter: "watch",
    type: "normal",
    error_key: null,
    removeUnneededInfo() {
      const type = this.get("type");
      if (type === "normal") {
        this.set("group_id", null);
      } else {
        this.set("category_id", null);
      }
    },
    category(categoryId) {
      if (categoryId) {
        return _category.default.findById(categoryId);
      } else {
        return false;
      }
    },
    filterName(filter) {
      return _I18n.default.t(`chat_integration.filter.${filter}`);
    },
    updateProperties() {
      return this.getProperties(["type", "category_id", "group_id", "tags", "filter"]);
    },
    createProperties() {
      return this.getProperties(["type", "channel_id", "category_id", "group_id", "tags", "filter"]);
    }
  }, (_applyDecoratedDescriptor(_obj, "available_filters", [_dec], Object.getOwnPropertyDescriptor(_obj, "available_filters"), _obj), _applyDecoratedDescriptor(_obj, "removeUnneededInfo", [_dec2], Object.getOwnPropertyDescriptor(_obj, "removeUnneededInfo"), _obj), _applyDecoratedDescriptor(_obj, "category", [_dec3], Object.getOwnPropertyDescriptor(_obj, "category"), _obj), _applyDecoratedDescriptor(_obj, "filterName", [_dec4], Object.getOwnPropertyDescriptor(_obj, "filterName"), _obj)), _obj)));
});